<!-- Table of documents (id, created_at, advisor, and document (via id) -->

<template>
  <div class="view-container">
    <v-snackbar v-model="showErrorSnackbar" color="error">
      {{ errorMessage }}
    </v-snackbar>
    <v-card style="width: 80%; margin: 100px auto 0;">
      <h1>{{ $t('documents.title') }}</h1>
      <v-table hover="true" fixed-header height="700px">
        <thead>
          <tr>
            <th class="text-center"> {{ $t('documents.id') }}</th>
            <th class="text-center"> {{ $t('documents.created_at') }}</th>
            <th class="text-center"> {{ $t('documents.advisor') }}</th>
            <th class="text-center"> {{ $t('documents.name') }}</th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="doc in docdata" :key="doc.id">
            <td><router-link :to="`/documents/${doc.id}`">{{ doc.id }}</router-link> </td>
            <td>{{ new Date(doc.created_at).toLocaleString('en-GB', {
              day: '2-digit', month: '2-digit', year: 'numeric',
              hour: '2-digit', minute: '2-digit' }) }}</td>
            <td v-if="doc.advisor"><router-link :to="`/advisors/${doc.advisor.id}`">{{ doc.advisor.first_name }} {{
              doc.advisor.last_name }}</router-link></td>
            <td v-else>{{ $t('documents.notAvailable') }}</td>
            <td>{{ doc.name }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { supabase, userState } from "../supabase";

let showErrorSnackbar = ref(false);
let errorMessage = ref("");
const docdata = ref([]);

onMounted(async () => {
  try {
    const user = userState.currentUser;
    if (!user) {
      console.error('User not found');
      showErrorSnackbar.value = true;
      errorMessage.value = 'User not found';
      return;
    }
    const { data: organisations, error: orgError } = await supabase
      .from('organisations')
      .select('id')
      .contains('associated_user_ids', [user.id]);
    if (orgError) {
      console.error(orgError);
      showErrorSnackbar.value = true;
      errorMessage.value = orgError.message;
      return;
    }

    const organisationIds = organisations.map(org => org.id);

    const { data: docs, error } = await supabase
      .from("documents")
      .select("*")
      .in('organisation', organisationIds);

    if (error) {
      console.error(error);
      showErrorSnackbar.value = true;
      errorMessage.value = error.message;
      return;
    }

    const filteredDocs = docs.filter(doc => doc.name !== null);
    filteredDocs.sort((a, b) => b.id - a.id);

    for (let doc of filteredDocs) {
      if (doc.advisor_user_id) {
        const { data: advisor, error } = await supabase
          .from("advisors")
          .select("*")
          .eq("user_id", doc.advisor_user_id)
          .in('organisation', organisationIds);
        if (error) {
          console.error(error);
          showErrorSnackbar.value = true;
          errorMessage.value = error.message;
          return;
        }
        doc.advisor = advisor[0];
      }
    }

    docdata.value = filteredDocs;
  } catch (error) {
    console.warn(error.message)
    showErrorSnackbar.value = true;
    errorMessage.value = error.message;
  }
});
</script>

<style scoped></style>