<!-- TO DO: What doesn't work is that you save the page when having changed nothing 
  - tries to write the leaders array in to "adviusors.leaders", instead of the leaders.ids
-->


<template>
  <div class="view-container">
    <v-card class="card rounded-xl"
      style="width: 100%; margin: 0 auto; padding: 25px; box-sizing: border-box; display: flex; flex-direction: column; align-items: left; justify-content: center; gap: 15px;">
      <v-breadcrumbs style="margin-left: 15px;">
        <v-breadcrumbs-item to="/:tenant/advisors" color="primary">{{ $t('advisorDetails.advisor')
          }}</v-breadcrumbs-item>
        >
        <v-breadcrumbs-item>{{ advisor.first_name }} {{ advisor.last_name }}</v-breadcrumbs-item>
      </v-breadcrumbs>
      <h1 style="text-align: left; margin-bottom:15px;margin-left: 30px">{{ $t('advisorDetails.advisor') }}</h1>
      <v-form style="max-width:350px; margin-left: 30px">
        <v-text-field :label="$t('advisorDetails.first_name')" variant="solo"
          v-model="advisor.first_name"></v-text-field>
        <v-text-field :label="$t('advisorDetails.last_name')" v-model="advisor.last_name" variant="solo"></v-text-field>
        <v-checkbox v-model="advisor.is_leader" :label="$t('advisorDetails.is_leader')" variant="solo">
        </v-checkbox>
        <v-select v-if="!advisor.is_leader" v-model="leader" :items="advisors" item-title="name" item-value="id"
          :label="advisor.first_name ? $t('advisorDetails.leader_of', { name: advisor.first_name }) : $t('advisorDetails.leader')"
          :multiple="false" chips variant="solo" clearable></v-select>
        <v-select v-if="advisor.is_leader" v-model="team" :items="advisors" item-title="name" item-value="id"
          :label="advisor.first_name ? $t('advisorDetails.team_of', { name: advisor.first_name }) : $t('advisorDetails.team')"
          multiple chips variant="solo" clearable=""></v-select>
        <v-btn style="margin-left: 30px" @click="saveAdvisor" class="primary-btn">{{ $t('advisorDetails.save')
          }}</v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { supabase, userState } from "../supabase";
import { ref, onMounted } from 'vue'
import axios from 'axios';
import { useRoute } from 'vue-router';


export default {
  setup() {
    const advisors = ref([]);
    const advisor = ref({
      first_name: '',
      last_name: '',
      is_leader: false,
    });
    const leader = ref(null);
    let leaderIds = ref([]);
    const team = ref([]);
    let reportIds = ref([]);
    const organisationIds = ref([]);
    const route = useRoute();


    onMounted(async () => {
      // fetch the tenant
      const user = userState.currentUser;
      if (!user) throw new Error('User not found')
      const { data: organisations, error: orgError } = await supabase
        .from('organisations')
        .select('id')
        .contains('associated_user_ids', [user.id])
      if (orgError) throw orgError
      organisationIds.value = organisations.map(org => org.id);

      // Extract the advisor ID from the URL
      const advisorId = route.params.id; // Make sure the route param name is 'id'


      // fetches the advisors for the specific org id
      const { data: advisorsData, error: advError } = await supabase
        .from('advisors')
        .select('id, first_name, last_name, organisation, leader, direct_reports')
        .in('organisation', organisationIds.value)
        .eq('id', advisorId)
      if (advError) throw advError

      advisor.value = advisorsData[0]
      advisors.value = advisorsData.map(a => ({
        id: a.id,
        name: `${a.first_name} ${a.last_name}`,
      }));

      // Process existing advisor data
      advisor.value = advisorsData.find(a => a.id === advisor.value.id) || advisor.value;

      if (advisor.value.is_leader && advisor.value.direct_reports) {
        team.value = advisor.value.direct_reports.map(reportId => advisors.value.find(a => a.id === reportId)?.name).filter(name => name);
      }

      if (advisor.value) {
        // Fetch leader
        if (advisor.value.leader && advisor.value.leader.length) {
          const { data: leadersData, error: leadersError } = await supabase
            .from('advisors')
            .select('id, first_name, last_name')
            .eq('id', advisor.value.leader[0]); // Use .in() for array of IDs
          if (leadersError) console.log(leadersError);
          if (Array.isArray(leadersData) && leadersData) {
            leader.value = leadersData.map(ldr => ({
              id: ldr.id,
              name: `${ldr.first_name} ${ldr.last_name}`
            }));
            leaderIds.value = leadersData.map(ldr => parseInt(ldr.id));
          }
          // Fetch direct reports
          if (advisor.value.direct_reports && advisor.value.direct_reports.length) {
            const { data: reportsData, error: reportsError } = await supabase
              .from('advisors')
              .select('id, first_name, last_name')
              .in('id', advisor.value.direct_reports); // Use .in() for array of IDs
            if (reportsError) console.log(reportsError);
            if (Array.isArray(reportsData) && reportsData) {
              advisor.value.is_leader = true;
              team.value = reportsData.map(report => ({
                id: report.id,
                name: `${report.first_name} ${report.last_name}`
              }));
              reportIds.value = reportsData.map(rptrs => parseInt(rptrs.id));

            }
          }
        }
      }
    });

    if (Array.isArray(leader.value) && leader.value.length > 0) {
      if (typeof leader.value[0] === 'object' && Object.prototype.hasOwnProperty.call(leader.value[0], 'id')) {
        leader.value = leader.value.map(ldr => ldr.id);
        console.log("leader is now:", leader.value)
      }
    }

    const saveAdvisor = async () => {
      const advisorData = {
        id: advisor.value.id,
        first_name: advisor.value.first_name,
        last_name: advisor.value.last_name,
        leader: leader.value ? [leader.value] : [],
        direct_reports: team.value || [],
        is_leader: advisor.value.direct_reports ? true : undefined,
      };
      console.log('Updating advisor with data:', advisorData);

      try {
        const response = await axios.put(process.env.VUE_APP_BACKEND_URL + '/api/updateadvisor', advisorData);

        if (response.status === 200) {
          console.log('Advisor updated successfully');
        }
      } catch (error) {
        console.error('Error updating advisor:', error);
      }
    };

    return {
      advisor,
      organisationIds,
      leader,
      leaderIds,
      team,
      saveAdvisor,
      advisors,
    };
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.primary-btn {
  background-color: #5932ea;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
</style>