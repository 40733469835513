<template>
    <p>{{ $t("googleCalendarOAuth.description") }}</p>
    <p>{{ $t("googleCalendarOAuth.remainingTime", { remainingTime: remainingTime }) }}</p>
</template>

<script setup>
import { onMounted } from 'vue'
import { ref } from 'vue'
import axios from 'axios'
import { useUser } from '../useUser';
import { useRoute, useRouter } from 'vue-router'
import { supabase, userState } from "../supabase";
const { user } = useUser();
const userId = ref(user.value ? user.value.id : null);
const route = useRoute()
const router = useRouter();
const remainingTime = ref(10);
const tenant = ref('')


onMounted(async () => {
    try {
        const code = route.query.code
        const user = userState.currentUser;
        const { data: organisations } = await supabase
            .from('organisations')
            .select('name')
            .contains('associated_user_ids', [user.id])
        const orgname = organisations.map(org => org.name)
        tenant.value = orgname[0]

        const tokenResponse = await axios.post(
            process.env.VUE_APP_BACKEND_URL + '/api/fetchtokensfromgoogle',
            { code: code, user_id: userId.value },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );

        // Check if the token fetch was successful
        if (tokenResponse.status === 200) {
            console.log('Successfully fetched tokens from Google:', tokenResponse.data);
            const eventsResponse = await axios.post(
                process.env.VUE_APP_BACKEND_URL + '/api/fetchgoogleevents',
                { user_id: userId.value },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );
            if (eventsResponse.status === 200) {
                console.log('Successfully fetched Google events:', eventsResponse.data);
            } else {
                console.error('Error fetching Google events:', eventsResponse);
            }
        } else {
            console.error('Error fetching tokens from Google:', tokenResponse);
        }
    }
    catch (error) {
        console.error('Error during OAuth flow:', error);
    }
    // Set up the timer for auto-transition
    const timer = setInterval(() => {
        remainingTime.value--;
        if (remainingTime.value === 0) {
            clearInterval(timer);
            router.push(`/${tenant.value}/`);
        }
    }, 1000);
})
</script>
