<template>
  <v-card style="margin: 20px; padding: 20px" class="card rounded-xl">
    <v-breadcrumbs style="margin-left: 15px;">
      <v-breadcrumbs-item to="/:tenant/settings" color="primary">{{ $t('viewAuth.title') }}</v-breadcrumbs-item>
      <v-breadcrumbs-item>{{ $t('viewAuth.titleCalendar') }}</v-breadcrumbs-item>
    </v-breadcrumbs>
    <div style="margin-bottom: 15px; padding: 25px; flex:auto; align-items: left;">
      <h1 style="text-align: left">{{ $t('viewAuth.titleCalendar') }}</h1>
      <p style="text-align: left">{{ $t('viewAuth.descriptionCalendar') }}</p>
      <br>
      <v-card-actions style="justify-content: left;">
        <v-spacer></v-spacer>
        <v-btn class="primary-btn" v-if="!googleconnected" @click="authenticateGoogle">
          <v-icon left>mdi-google</v-icon>
          {{ $t('viewAuth.google') }}
        </v-btn>
        <v-btn class="primary-btn" v-if="googleconnected" @click="deactivateGoogle">
          <v-icon left>mdi-google</v-icon>
          {{ $t('viewAuth.googleDisconnect') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="primary-btn" v-if="!microsoftconnected" @click="authenticateMicrosoft">
          <v-icon left>mdi-microsoft</v-icon>
          {{ $t('viewAuth.microsoft') }}
        </v-btn>
        <v-btn class="primary-btn" v-if="microsoftconnected" @click="deactivateMicrosoft">
          <v-icon left>mdi-microsoft</v-icon>
          {{ $t('viewAuth.microsoftDisconnect') }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios';
import { ref, onMounted } from 'vue';
import { supabase, userState } from "../supabase";


export default {
  setup() {
    const googleconnected = ref(false);
    const microsoftconnected = ref(false);
    const authorized = ref(false);
    const calendarItems = ref([]);

    const authenticateGoogle = async () => {
      try {
        const currentUser = userState.currentUser;
        const userId = currentUser.id;
        const response = await axios.get(process.env.VUE_APP_BACKEND_URL + '/api/authorizegoogle', {
          params: { user_id: userId }
        }

        );
        window.location.href = response.data.url;
      } catch (error) {
        console.error('Authorization failed:', error);
      }
    };

    const authenticateMicrosoft = async () => {
      try {
        const currentUser = userState.currentUser;
        const userId = currentUser.id;
        const response = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/authorizemicrosoft`, {
          params: { user_id: userId }
        });
        window.location.href = response.data.url;
      } catch (error) {
        console.error('Authorization failed:', error);
      }
    };

    const checkMicrosoftConnected = async () => {
      try {
        const currentUser = userState.currentUser;
        const { data, error } = await supabase
          .from('calendar_auth')
          .select('active, calendar_type')
          .eq('user_id', currentUser.id)
          .eq('calendar_type', 'Microsoft')


        if (data && data.length > 0) {
          // Check if data.active is true for any of the entries
          microsoftconnected.value = data.some(entry => entry.active === true);
        } else {
          microsoftconnected.value = false;
        }
        if (error) {
          console.error('Error fetching calendar details:', error);
        }
      } catch (error) {
        console.error('Error in checkMicrosoftConnected:', error);
      }
    };


    const checkGoogleConnected = async () => {
      try {
        const currentUser = userState.currentUser;
        const { data, error } = await supabase
          .from('calendar_auth')
          .select('active, calendar_type')
          .eq('user_id', currentUser.id)
          .eq('calendar_type', 'Google')


        if (data && data.length > 0) {
          // Check if data.active is true for any of the entries
          googleconnected.value = data.some(entry => entry.active === true);
        } else {
          googleconnected.value = false;
        }
        if (error) {
          console.error('Error fetching calendar details:', error);
        }
      } catch (error) {
        console.error('Error in checkGoogleConnected:', error);
      }
    };

    const deactivateGoogle = async () => {
      try {
        const currentUser = userState.currentUser;
        await supabase
          .from('calendar_auth')
          .update({ active: false })
          .eq('user_id', currentUser.id)
          .eq('calendar_type', 'Google');
        googleconnected.value = false;
        console.log("googleconnected.value", googleconnected.value)
      } catch (error) {
        console.error('Error in deactivateGoogle:', error);
      }
    };

    const deactivateMicrosoft = async () => {
      try {
        const currentUser = userState.currentUser;
        await supabase
          .from('calendar_auth')
          .update({ active: false })
          .eq('user_id', currentUser.id)
          .eq('calendar_type', 'Microsoft');
        microsoftconnected.value = false;
        console.log("Microsoftconnected.value", microsoftconnected.value)
      } catch (error) {
        console.error('Error in deactivateGoogle:', error);
      }
    };

    onMounted(() => {
      checkGoogleConnected();
      checkMicrosoftConnected();
    });

    return {
      googleconnected,
      microsoftconnected,
      authorized,
      calendarItems,
      authenticateGoogle,
      authenticateMicrosoft,
      checkGoogleConnected,
      deactivateGoogle,
      deactivateMicrosoft
    };
  }
};
</script>

<style scoped>
.primary-btn {
  background-color: #5932ea;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
</style>