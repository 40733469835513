<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-title class="h3-style">{{ $t('NERAccordion.NER') }}</v-expansion-panel-title>
            <v-expansion-panel-text>
                <div class="d-flex">
                    <div class="flex-grow-1 text-left" v-html="formattedContent"></div>
                    <v-btn style="align-self: flex-start;" icon small @click="copyToClipboard"
                        :color="copySuccess ? 'success' : 'primary'">
                        <v-icon>{{ copySuccess ? 'mdi-check' : 'mdi-content-copy' }}</v-icon>
                    </v-btn>
                </div>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    props: {
        nerResults: String
    },
    data() {
        return {
            copySuccess: false
        }
    },
    computed: {
        formattedContent() {
            try {
                const parsedData = JSON.parse(this.nerResults);
                const nerObject = parsedData.NER[0];
                const formattedLines = Object.entries(nerObject).map(([key, value], index) => {
                    const formattedValue = value === 'N/A'
                        ? `<span style="color: #9197b3;">${value}</span>`
                        : value;
                    return `${index + 1}. ${key} ${formattedValue}`;
                });
                return formattedLines.join('<br>');
            } catch (error) {
                console.error('Error parsing NER results:', error);
                return 'Error parsing NER results';
            }
        }
    },
    methods: {
        copyToClipboard() {
            navigator.clipboard.writeText(this.nerResults).then(() => {
                this.copySuccess = true;
                setTimeout(() => {
                    this.copySuccess = false;
                }, 2000);
            });
        }
    }
}
</script>

<style scoped>
.h3-style {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 0.5em;
}
</style>