<template>
  <div v-if="showSidebar">
    <v-navigation-drawer expand-on-hover rail permanent height='100%' color="white">
      <v-card class="mb-4 logo-card">
        <img src="../assets/logosmall.png" alt="Logo" style="max-width:75%" />
      </v-card>
      <v-list density="compact" nav>
        <v-list-item v-for="item in menuItems" :key="item.name" :prepend-icon="item.icon" :title="$t(item.title)"
          :to="item.route" :value="item.name"
          :class="{ highlighted: isActiveMenuItem(item), 'icon-csecondary': !isActiveMenuItem(item) }"
          @click="handleNavigation(item)">
          <template v-slot:prepend-icon>
            <component v-if="item.iconComponent" :is="item.iconComponent" />
            <v-icon v-else>{{ item.icon }}</v-icon>
          </template>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list>
          <v-list-item :prepend-avatar="avatarUrl" :subtitle="currentUser ? currentUser.email : 'Please log in'"
            :title="advisorData ? `${advisorData.first_name}` : 'Guest'"></v-list-item>
          <v-list-item v-if="!currentUser" title="Login" to="/login"></v-list-item>
          <v-list-item v-if="!currentUser" title="Registrieren" to="/register"></v-list-item>
        </v-list>
      </template>
      <v-divider></v-divider>
    </v-navigation-drawer>
  </div>
</template>


<script>
import { ref, watch, computed } from "vue"; // watchEffect is new
import { supabase } from "../supabase";
import { userState } from '../supabase.js';
import { useRoute } from 'vue-router';

import { Library, ArrowUpFromLine, UsersRound, Home, LogOut, Bolt, Gem, BookOpen } from 'lucide-vue-next';
export default {
  computed: {
    currentUser() {
      return userState.currentUser;
    },
    showSidebar() {
      const route = useRoute();
      return route.path !== '/login'
        && route.path !== '/password-reset'
        && route.path !== '/register'
        && route.path !== '/update-password';
    },
    tenant() {
      const route = useRoute();
      return route.params.tenant;
    },
  },


  methods: {
    isActiveMenuItem(item) {
      const route = useRoute();
      return item.name === route.meta.activeMenuItem;
    },
    handleNavigation(item) {
      if (item.isExternal) {
        window.open(item.route, '_blank');
      } else {
        this.$router.push(item.route);
      }
    }
  },

  setup() {
    const advisorData = ref(null);
    const avatarUrl = ref(null);
    const route = useRoute();
    const tenantComputed = computed(() => route.params.tenant);
    const activeMenuName = ref(null);  // Use ref for reactive state in Composition API

    // Watch for route changes
    watch(() => route.meta.activeMenuItem, (newVal) => {
      activeMenuName.value = newVal;
    }, { immediate: true });

    const menuItems = computed(() => [
      { name: 'dashboard', title: 'sidebar.dashboard', icon: Home, route: `/${tenantComputed.value}/` },
      {
        name: 'calls',
        title: 'sidebar.calls',
        icon: Library,
        route: `/${tenantComputed.value}/calls/`
      },
      {
        name: 'upload',
        title: 'sidebar.upload',
        icon: ArrowUpFromLine,
        route: `/${tenantComputed.value}/upload`
      },
      { name: 'customers', title: 'sidebar.customers', icon: Gem, route: `/${tenantComputed.value}/customers` },
      { name: 'advisors', title: 'sidebar.team', icon: UsersRound, route: `/${tenantComputed.value}/advisors` },
      { name: 'settings', title: 'sidebar.settings', icon: Bolt, route: `/${tenantComputed.value}/settings/` },
      { name: 'documentation', title: 'sidebar.documentation', icon: BookOpen, route: "https://michael-ga.notion.site/cc2069ab06804277a4b477a5371abe78?v=2154b23ab7e64b1f83e6cc9e04acd8e3&pvs=4", isExternal: true },
      { name: 'logout', title: 'sidebar.logout', icon: LogOut, route: `/logout` },
    ]);

    // Function to update menu items based on tenant
    const updateMenuItems = () => {
      menuItems.value = [
        { name: 'dashboard', title: 'sidebar.dashboard', icon: Home, route: `/${tenantComputed.value}/` },
        { name: 'calls', title: 'sidebar.calls', icon: 'mdi-folder', route: `/${tenantComputed.value}/calls/` },
        { name: 'upload', title: 'sidebar.upload', icon: 'mdi-upload', route: `/${tenantComputed.value}/upload` },
        { name: 'customers', title: 'sidebar.customers', icon: Gem, route: `/${tenantComputed.value}/customers` },
        { name: 'advisors', title: 'sidebar.team', icon: UsersRound, route: `/${tenantComputed.value}/advisors` },
        { name: 'settings', title: 'sidebar.settings', icon: Bolt, route: `/${tenantComputed.value}/settings/` },
        { name: 'documentation', title: 'sidebar.documentation', icon: BookOpen, route: "https://michael-ga.notion.site/cc2069ab06804277a4b477a5371abe78?v=2154b23ab7e64b1f83e6cc9e04acd8e3&pvs=4", isExternal: true },
        { name: 'logout', title: 'sidebar.logout', icon: 'mdi-logout', route: `/logout` },
      ];
    };

    updateMenuItems(tenantComputed.value);
    // Watch for changes in tenantComputed and update menu items
    watch(tenantComputed, (newTenant) => {
      updateMenuItems(newTenant);
    });

    const getData = async () => {
      if (!userState.currentUser) {
        advisorData.value = null;
        return;
      }
      try {
        const { data: advisors, error } = await supabase
          .from('advisors')
          .select('first_name, last_name, avatar_url')
          .eq('user_id', userState.currentUser.id.toString());
        if (error) throw error;
        if (advisors && advisors.length > 0) {
          advisorData.value = advisors[0];
          const avatarPath = advisors[0].avatar_url;
          if (avatarPath) {
            const { data: imageBlob, error: imageError } = await supabase.storage.from('coveredbucket').download(avatarPath);
            if (imageError) {
              console.warn('Error downloading image:', imageError);
            } else {
              avatarUrl.value = URL.createObjectURL(imageBlob);
            }
          } else {
            avatarUrl.value = null;
          }
        }
      } catch (error) {
        console.warn(error.message)
      }
    }
    watch(() => userState.currentUser, getData, { immediate: true });

    async function logout() {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.log(error);
      } else {
        console.log("Sign out success");
        window.location.href = 'https://www.usetoday.io';
      }
    }

    return {
      advisorData,
      avatarUrl,
      logout,
      menuItems,
      activeMenuName
    };
  },
};
</script>

<style scoped>
.logo-card {
  box-shadow: none !important;
  border: none !important;
}

.icon-csecondary {
  color: #9197B3 !important;
}

.highlighted {
  background-color: rgba(var(--v-theme-primary), 1);
  color: white !important;
  opacity: 1 !important;
  /* Override opacity */
}

.highlighted .v-icon {
  opacity: 1 !important;
  /* Ensure icons have full opacity */
}


.v-list-item.highlighted {
  background-color: rgba(var(--v-theme-primary), 1) !important;
  color: white !important;
  opacity: 1 !important;
}

.highlighted .v-icon {
  opacity: 1 !important;
  /* Ensure 100% opacity for icons in highlighted class */
}

.v-list-item.highlighted .v-icon {
  opacity: 1 !important;
}
</style>