import { createApp, nextTick } from 'vue'; // nextTick for Posthog
import App from './App.vue';

import i18n from './i18n';
import router from './router';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/dist/vuetify.min.css'
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';

// new for posthog
import posthogPlugin from "/plugins/posthog";

// new for sentry
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://65a8bfc1a7c58ef6d59a0062fe3b01ff@o4507033516572672.ingest.us.sentry.io/4507033518211072",

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  release: "my-project-name@2.3.12",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/app.usetoday\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  components,
  directives,
  defaults: {
    Vbtn: {
      color: "primary",
      textTransform: 'none',
    },
  },
  theme: {
    themes: {
      light: {
        primary: '#5932EA',
        csecondary: '#9197B3',
        backgroundsecondary: '#F2F2F7',
        background: '#FAFBFF',
        success: '#65C8CD',
        error: '#EC5B84',
      },
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
})


const app = createApp(App);
app.use(posthogPlugin)
  .use(i18n)
  .use(router)
  .use(Donut)
  .use(vuetify)
  .mount('#app');

router.afterEach((to, from, failure) => {
  if (!failure) {
    nextTick(() => {
      app.config.globalProperties.$posthog.capture('$pageview', { path: to.fullPath });
    });
  }
});