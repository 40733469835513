<!-- UI to create org in supabase and associate current user with it. 
  Part of the onboarding and redirect (router) if no tenant present. -->
<template>
  <v-row justify="center">
    <v-col class="d-flex align-center justify-center">
      <v-snackbar v-model="showErrorSnackbar" color="error">
        {{ errorMessage }}
      </v-snackbar>
      <v-card class="view-card" rounded="xl">
        <form @submit.prevent="createOrganisation" class="form-content">
          <h2 style="padding: 20px;">{{ $t('create.createOrg') }}</h2>
          <v-text-field v-model="firstName" :label="$t('create.firstName')" required density="compact" variant="solo"
            class="input-field form-item"></v-text-field>

          <v-text-field v-model="organisation" :label="$t('create.organisation')" id="organisation" variant="solo"
            density="compact" max-width="200px" required class="input-field form-item"></v-text-field>

          <v-btn @click="createOrganisation" class="me-2 text-none btn-spacing" color="#5932ea" width="200"
            rounded="lg">
            {{ $t('create.create') }}
          </v-btn>
        </form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup>
import { ref } from "vue";
import { supabase } from "../supabase"
import { useUser } from "../useUser";
import { useRouter } from 'vue-router';

const router = useRouter();
const { user } = useUser();

let firstName = ref("");
let organisation = ref("");

let showErrorSnackbar = ref(false);
let errorMessage = ref("");

let customerId = ref("");

// create new organisation in "organisations" table
async function createOrganisation() {
  const currentUser = user.value.id;
  const { data: orgData, error: orgError } = await supabase
    .from("organisations")
    .upsert([{ name: organisation.value.replace(/\s/g, ''), associated_user_ids: [currentUser] }])
    .select('id');
  if (orgError) {
    console.error(orgError);
  }
  // Assuming the organisation was created successfully and we have the id
  const newOrgId = orgData[0].id;


  // ensure current firstName carries over - already set on signup
  // create new entry in "advisors" table
  const { data: advisorData, error: advisorError } = await supabase
    .from("advisors")
    .insert([{ first_name: firstName.value, user_id: currentUser, last_name: "Advisor", organisation: newOrgId }])
    .select('id');
  if (advisorError) {
    console.error(advisorError);
    showErrorSnackbar.value = true;
    errorMessage.value = advisorError.message;
  }
  const advisorId = advisorData[0].id; // Capture the advisor ID
  console.log("advisor created", advisorId);

  // create new entry in "customers" table  
  const { data: customerData, error: customerError } = await supabase
    .from("customers")
    .insert([{ first_name: "Demo", last_name: "Kunde", organisation: newOrgId }])
    .select('id');
  if (customerError) {
    console.error(customerError);
    showErrorSnackbar.value = true;
    errorMessage.value = customerError.message;
    return;
  }
  customerId.value = customerData[0].id; // Capture the customer ID


  // duplicate call #230
  const { data: callData, error: callError } = await supabase
    .from('calls')
    .select('file_reference, transcript_file_name, insights, conversation_type, scorecard_id, coaching_available')
    .eq('id', 230)
    .single();
  console.log("call data is", callData)

  if (callError) {
    console.error(callError);
    showErrorSnackbar.value = true;
    errorMessage.value = callError.message;
    return;
  }

  if (!callData) {
    console.error('No data returned for call with id 230');
    showErrorSnackbar.value = true;
    errorMessage.value = 'No data returned for call with id 230';
    return;
  }

  // Load the transcript file from Supabase storage using the file_reference from the calls table
  console.log("transcriptfilename is:", callData.transcript_file_name)
  const transcriptFileName = callData.transcript_file_name;
  const fileNameWithoutPath = Array.isArray(transcriptFileName) && transcriptFileName.length > 0 ? transcriptFileName[0].split('/').pop() : '';
  // const signedTranscriptFileUrl = fileNameWithoutPath ? `https://tswijmzbixtawidabpuq.supabase.co/storage/v1/object/sign/coveredbucket/${fileNameWithoutPath}` : '';
  let transcriptFile = null;

  // Fetch the transcript file from the storage
  transcriptFile = await supabase
    .storage
    .from('coveredbucket')
    .download(fileNameWithoutPath);
  if (transcriptFile.error) {
    throw transcriptFile.error;
  }

  // Parse the JSON content of the transcript file
  let transcriptContent;
  try {
    transcriptContent = JSON.parse(await transcriptFile.data.text());
  } catch (jsonError) {
    console.error('Failed to parse transcript JSON:', jsonError);
    showErrorSnackbar.value = true;
    errorMessage.value = 'Failed to parse transcript JSON';
    return;
  }
  // Replace the keys in the transcript JSON content
  if (!Array.isArray(transcriptContent.transcription)) {
    console.error('transcriptContent.transcription is not an array:', transcriptContent.transcription);
    showErrorSnackbar.value = true;
    errorMessage.value = 'Invalid transcript content format';
    return;
  }
  // here is the problem: write user id instead of adviosor.id
  transcriptContent.transcription = transcriptContent.transcription.map(entry => {
    if (entry.role_id === "082d87a4-4988-4e9a-8a8b-67908e4bd1dc") {
      entry.role_id = currentUser;
    } else if (entry.role_id === "13") {
      entry.role_id = customerId.value;
    }
    return entry;
  });

  console.log("Full transcript content:", JSON.stringify(transcriptContent, null, 2));

  const fileBaseName = fileNameWithoutPath.replace('.json', '');
  const transcriptwithupdatedkeys = `${fileBaseName}-${newOrgId}.json`;
  const newtranscripturl = transcriptwithupdatedkeys ? `https://tswijmzbixtawidabpuq.supabase.co/storage/v1/object/sign/coveredbucket/${transcriptwithupdatedkeys}` : '';
  console.log("newtranscripturl", newtranscripturl)

  // Convert the updated transcript content back to a Blob for upload
  const updatedTranscriptBlob = new Blob([JSON.stringify(transcriptContent)], { type: 'application/json' });

  // Store the updated transcript file in Supabase under the same filename
  const { error: updateTranscriptError } = await supabase
    .storage
    .from('coveredbucket')
    .upload(transcriptwithupdatedkeys, updatedTranscriptBlob, {
      cacheControl: '3600',
      upsert: true
    });


  if (updateTranscriptError) {
    console.error(updateTranscriptError);
    showErrorSnackbar.value = true;
    errorMessage.value = updateTranscriptError.message;
    return;
  }

  // eslint-disable-next-line
  const { id, ...callDataWithoutId } = callData;
  const callToInsert = { ...callDataWithoutId, user_id: currentUser, organisation: newOrgId, customer_id: customerId.value, transcript_file_name: [newtranscripturl] };
  const { data: insertedCallData, error: insertedCallError } = await supabase
    .from('calls')
    .insert([callToInsert])
    .select()

  if (insertedCallError) {
    console.error(insertedCallError);
    showErrorSnackbar.value = true;
    errorMessage.value = insertedCallError.message;
    return;
  }

  if (!insertedCallData || insertedCallData.length === 0) {
    console.error('No data returned after inserting call');
    showErrorSnackbar.value = true;
    errorMessage.value = 'No data returned after inserting call';
    return;
  }

  const newCallUuid = insertedCallData[0].id;
  console.log("newcallid:", newCallUuid)

  const { data: updatedOrgData, error: updateOrgError } = await supabase
    .from('organisations')
    .update({ demo_call: newCallUuid })
    .eq('id', newOrgId);
  if (updateOrgError) {
    console.error(updateOrgError);
    showErrorSnackbar.value = true;
    errorMessage.value = updateOrgError.message;
    return;
  }
  console.log("updated org data is:", updatedOrgData)

  await fetchTenantAndRedirect(currentUser);
}

async function fetchTenantAndRedirect(currentUser) {
  const { data: tenantData, error: tenantError } = await supabase
    .from('organisations')
    .select('name')
    .contains('associated_user_ids', [currentUser]);
  if (tenantError) {
    console.error('Error fetching tenant:', tenantError);
    showErrorSnackbar.value = true;
    errorMessage.value = tenantError.message;
  }
  // Redirect to /:tenant/
  if (tenantData && tenantData.length > 0) {
    const tenant = tenantData[0].name;
    router.push(`/${tenant}/`);
  }
}
</script>

<style scoped>
.btn-spacing {
  margin: 10px;
}

.view-card {
  width: 400px;
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.form-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form-item {
  margin-bottom: 35px;
}

.input-field {
  width: calc(100% - 40px);
  box-shadow: 0px 0px 0px 1px #868FA0 16%;
}

h2 {
  padding: 20px;
}

v-text-field {
  width: 100%;
}

body,
html {
  height: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>