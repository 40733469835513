<template>
  <div class="view-container">
    <v-card
      style="width: 80%; margin: 0 auto; padding: 25px; box-sizing: border-box; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 15px;">
      <h1>{{ $t('documentTypes.title') }}</h1>
      <v-table>
        <thead>
          <tr>
            <th class="text-center">{{ $t('documentTypes.id') }}</th>
            <th class="text-center">{{ $t('documentTypes.name') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="document in documents" :key="document.id">
            <td><router-link :to="`/:tenant/document-types/${document.id}`">{{ document.id }}</router-link></td>
            <td><router-link :to="`/:tenant/document-types/${document.id}`">{{ document.name }}</router-link></td>
          </tr>
        </tbody>
      </v-table>
    </v-card>
    <v-snackbar v-model="showErrorSnackbar" color="error">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script setup>
import { supabase, userState } from "../supabase";
import { onMounted, ref } from 'vue'

const documents = ref([])
const loading = ref(true)

let showErrorSnackbar = ref(false);
let errorMessage = ref("");

onMounted(async () => {
  try {
    // gets current user
    const user = userState.currentUser;
    if (!user) {
      console.error('User not found');
      showErrorSnackbar.value = true;
      errorMessage.value = 'User not found';
      return;
    }

    // This is how you filter an array column to match a specific uuid
    const { data: organisations, error: orgError } = await supabase
      .from('organisations')
      .select('id')
      .contains('associated_user_ids', [user.id])
    if (orgError) throw orgError
    const organisationIds = organisations.map(org => org.id);

    // fetches the document types for the specific org id
    const { data: documentTypes, error: advError } = await supabase
      .from('document_types')
      .select('*')
      .in('organisation', organisationIds);
    if (advError) {
      console.error(advError);
      showErrorSnackbar.value = true;
      errorMessage.value = advError.message;
      return;
    }
    documents.value = documentTypes

  } catch (error) {
    console.error('Error: ', error.message)
    showErrorSnackbar.value = true;
    errorMessage.value = error.message;
  } finally {
    loading.value = false
  }
})
</script>

<style scoped>
/* Component styles go here */
</style>