<!-- table view of advisors with id, prename, lastname  -->
<template>
  <div class="card-container">
    <v-card style="width: 100%;" class="card rounded-xl">
  <v-breadcrumbs style="margin-left: 15px;">
    <v-breadcrumbs-item>{{ $t('viewAdvisor.advisor') }}</v-breadcrumbs-item>
  </v-breadcrumbs>

  <div class="card-snackbar-wrapper">
    <v-snackbar v-model="showSnackbar" color="success">
      {{ $t('viewAdvisor.passwordResetEmailSent') }}
    </v-snackbar>
    <v-snackbar v-model="showErrorSnackbar" color="error">
      {{ errorMessage }}
    </v-snackbar>
  </div>

  <div style="margin-bottom: 15px; padding: 25px;">
    <h1 style="text-align: left">{{ $t('viewAdvisor.advisor') }}</h1>
  </div>

  <div style="margin-bottom: 15px; padding: 25px; display: flex; justify-content: start;">
    <div></div> <!-- This is an empty div to balance the alignment -->
    <div class="text-right">
      <v-btn color="primary" @click="routeToAddAdvisor">
        <v-icon left>mdi-plus</v-icon>
        {{ $t('viewAdvisor.addAdvisor') }}
      </v-btn>
    </div>
  </div>

  <!-- Table -->
  <div style="padding: 25px;">
    <v-table hover="true" fixed-header>
      <thead>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-center">{{ $t('viewAdvisor.first_name') }}</th>
          <th class="text-center">{{ $t('viewAdvisor.last_name') }}</th>
          <th class="text-center">{{ $t('viewAdvisor.actions') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="advisor in advisors" :key="advisor.id">
          <td>
            <router-link :to="`/:tenant/advisors/${advisor.id}`">{{ advisor.id }}</router-link>
          </td>
          <td>
            <router-link :to="`/:tenant/advisors/${advisor.id}`">{{ advisor.first_name }}</router-link>
          </td>
          <td>
            <router-link :to="`/:tenant/advisors/${advisor.id}`">{{ advisor.last_name }}</router-link>
          </td>
          <td class="text-center">
            <v-btn variant="tonal" small color="csecondary" @click="resetAdvisorPassword(advisor.email)">
              {{ $t('viewAdvisor.resetPassword') }}
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</v-card>

  </div>
</template>

<script setup>
import { supabase, userState } from "../supabase";
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router';

const router = useRouter();
const advisors = ref([])
const loading = ref(true)
let showSnackbar = ref(false);
let showErrorSnackbar = ref(false);
let errorMessage = ref("");
const routeToAddAdvisor = () => {
  router.push({ name: 'AdvisorsAdd' });
};

const resetAdvisorPassword = async (email) => {
  if (!email) {
    errorMessage.value = "t('viewAdvisor.emailMissing')";
    showErrorSnackbar.value = true;
    return;
  }
  try {
    await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: 'https://app.usetoday.io/update-password',
    });
    showSnackbar.value = true;
  } catch (error) {
    errorMessage.value = "t('viewAdvisor.errorSendingInvitation')";
    showErrorSnackbar.value = true;
  }
};


onMounted(async () => {
  try {
    // gets current user
    const user = userState.currentUser;
    if (!user) throw new Error("t('viewAdvisor.userNotFound')")

    // This is how you filter an array column to match a specific uuid
    const { data: organisations, error: orgError } = await supabase
      .from('organisations')
      .select('id')
      .contains('associated_user_ids', [user.id])
    if (orgError) throw orgError
    const organisationIds = organisations.map(org => org.id);

    // fetches the advisors for the specific org id
    const { data: advisorsData, error: advError } = await supabase
      .from('advisors')
      .select('*')
      .in('organisation', organisationIds);

    if (advError) throw advError
    advisors.value = advisorsData

  } catch (error) {
    console.error('Error: ', error.message)
  } finally {
    loading.value = false
  }
})
</script>

<style scoped></style>