import { ref } from "vue";
import { supabase } from "./supabase"; 

const user = ref(null);

supabase.auth.onAuthStateChange(async (event, session) => {
    user.value = session ? session.user : null;
});

export function useUser() {
    return { user };
}

