<template>
	<v-row justify="center">
		<v-col class="d-flex align-center justify-center">
			<div class="card-snackbar-wrapper">
				<v-card class="view-card" rounded="xl">
					<div class="form-content">
						<v-icon large color="primary">mdi-email</v-icon>
						<h2>{{ $t('confirmation.title') }}</h2>
						<p>{{ $t('confirmation.description', { email: email }) }}</p>
					</div>
				</v-card>
			</div>
		</v-col>
	</v-row>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";

let email = ref("");
const route = useRoute();

onMounted(() => {
	email.value = route.query.email;
});
</script>

<style scoped>
.card-snackbar-wrapper {
	width: 400px;
	max-width: 90%;
	/* Prevent the wrapper from being too wide on small screens */
	margin: 0 auto;
	/* Center the wrapper */
}

.view-card {
	width: 100%;
	padding: 20px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	/* Add drop shadow */
}

.form-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

h2 {
	padding: 20px;
}

/* If you want the container to also be centered vertically on the page */
body,
html {
	height: 100%;
}

body {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>