<template>
  <v-card style="margin: 20px; padding: 20px" class="card rounded-xl">
    <v-breadcrumbs style="margin-left: 15px;">
      <v-breadcrumbs-item to="/:tenant/settings" color="primary">{{ $t("settingsGeneral.settings")
        }}</v-breadcrumbs-item>
      <v-breadcrumbs-item>{{ $t("settingsGeneral.title") }}</v-breadcrumbs-item>
    </v-breadcrumbs>
    <div style="align-items:left;margin-left:30px">
      <img v-if="avatarUrl" class="image" :src="avatarUrl" :key="avatarUrl" alt="Avatar" style="max-width:25%" />
      <p v-for="(org, index) in organisations" :key="index">
        Organisation: {{ org ? org.name : 'Loading...' }} (ID: {{ org ? org.id : 'Loading...' }})
        <v-btn v-if="org" @click="setAsTenantId(org.id)">{{ $t("settingsGeneral.setAsTenantId") }}</v-btn>
      </p>
      <v-list>
        <v-list-item :subtitle="currentUser ? currentUser.email : 'Please log in'"
          :title="advisorData ? `${advisorData.first_name} ${advisorData.last_name || ''}`.trim() : 'Guest'"
          class="text-left"></v-list-item>
      </v-list>
      <v-select :items="languages" item-value="code" item-title="name" :label="$t('settingsGeneral.language')"
        density="compact" v-model="selectedLanguage" style="width: 200px;"
        @update:model-value="changeLanguage"></v-select>
    </div>
  </v-card>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { supabase, userState } from "../supabase";
import { useRoute } from "vue-router";
import { useUser } from "../useUser";
import i18n from "../i18n";
import axios from 'axios';

export default {
  computed: {
    currentUser() {
      return userState.currentUser;
    },
  },
  setup() {
    const languages = ref([
      { code: 'de', name: 'Deutsch' },
      { code: 'en', name: 'English' },
      { code: 'dk', name: 'Dansk' },
      { code: 'pt', name: 'Português' }
    ]);
    const selectedLanguage = ref(localStorage.getItem('selectedLanguage') || 'de');

    const organisations = ref([]);
    const advisorData = ref(null);
    const avatarUrl = ref(null);
    const route = useRoute();
    const id = ref(route.params.id);
    const organisationIds = ref([]);
    const { user } = useUser();
    const isAdmin = computed(() => user.value && (user.value.id === '082d87a4-4988-4e9a-8a8b-67908e4bd1dc'));

    const changeLanguage = (languageCode) => {
      localStorage.setItem('selectedLanguage', languageCode);

      i18n.global.locale = languageCode;
      try {
        const response = axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/api/send_language`,
          { value: languageCode },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );


        console.log('Language sent successfully:', response);

      } catch (error) {
        console.error('Error sending language:', error);

      }
    }

    onMounted(async () => {
      id.value = route.params.id;
      const user = userState.currentUser;
      if (!user) throw new Error('User not found');

      // This is how you filter an array column to match a specific uuid
      const { data: organisations, error: orgError } = await supabase
        .from('organisations')
        .select('id')
        .contains('associated_user_ids', [user.id]);
      if (orgError) {
        throw orgError;
      }
      organisationIds.value = organisations.map(org => org.id);

      const { data: advisors, error: advError } = await supabase
        .from('advisors')
        .select('first_name, last_name, avatar_url')
        .eq('user_id', userState.currentUser.id.toString())
        .in('organisation', organisationIds.value) // new for multi-tenancy

      if (advError || orgError) throw advError || orgError;

      advisorData.value = advisors.length > 0 ? advisors[0] : null;

      const avatarPath = advisors[0].avatar_url;
      // const { data: imageBlob } = await supabase.storage.from('coveredbucket').download(avatarPath);
      // avatarUrl.value = URL.createObjectURL(imageBlob);
      if (avatarPath) {
        const { data: imageBlob, error: imageError } = await supabase.storage.from('coveredbucket').download(avatarPath);
        if (imageBlob) {
          avatarUrl.value = URL.createObjectURL(imageBlob);
        } else {
          // Handle the error if imageBlob is not available
          console.error('Failed to download avatar image:', imageError);
        }
      } else {
        // If avatarPath is not available, don't set avatarUrl
        avatarUrl.value = null;
      }
    });

    return {
      avatarUrl,
      organisations,
      advisorData,
      organisationIds,
      isAdmin,
      languages,
      selectedLanguage,
      changeLanguage
    };
  },
}
</script>

<style scoped>
.image {
  display: block;
}
</style>