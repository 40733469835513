<template>
	<v-row justify="center">
		<v-col class="d-flex align-center justify-center">
			<div class="card-snackbar-wrapper">
				<v-snackbar v-model="showSnackbar" color="success">
					{{ $t("updatePassword.success") }}
				</v-snackbar>
				<v-snackbar v-model="showErrorSnackbar" color="error">
					{{ errorMessage }}
				</v-snackbar>
				<v-card class="view-card" rounded="xl">
					<form @submit.prevent="submit" class="form-content">
						<h2 style="padding: 20px;">{{ $t("updatePassword.title") }}</h2>
						<v-text-field v-model="password" label="Passwort" id="password" type="password" variant="solo"
							density="compact" max-width="200px" required class="input-field form-item"></v-text-field>
						<v-text-field v-model="confirmPassword" label="Passwort bestätigen" id="confirm-password"
							type="password" variant="solo" density="compact" max-width="200px" required
							class="input-field form-item"></v-text-field>
						<v-btn @click="updatePassword" class="me-2 text-none btn-spacing" color="#5932ea" width="200"
							rounded="lg">
							{{ $t("updatePassword.update") }}
						</v-btn>
					</form>
				</v-card>
			</div>
		</v-col>
	</v-row>
</template>

<script setup>
import { ref } from 'vue';
import { supabase } from '../supabase';
import { useRouter } from 'vue-router'
import { useForm } from 'vee-validate'

let password = ref('');
let confirmPassword = ref('');
const router = useRouter();
let showSnackbar = ref(false);
let showErrorSnackbar = ref(false);
let errorMessage = ref("");

async function updatePassword() {
	if (password.value !== confirmPassword.value) {
		errorMessage.value = "Passworte stimmen nicht überein";
		showErrorSnackbar.value = true;
		return;
	}
	const { data, error } = await supabase.auth.updateUser({ password: password.value })
	if (error) {
		errorMessage.value = error.message;
		showErrorSnackbar.value = true;
	} else {
		console.log(data);
		showSnackbar.value = true;
		router.push("/:tenant/");
	}
}

const { handleSubmit, errors } = useForm({
	validationSchema: {
		email(value) {
			if (/^[a-z.-]+@[a-z.-]+\.[a-z]+$/i.test(value)) return true
			return 'Must be a valid e-mail.'
		},
	},
})

const submit = handleSubmit(values => {
	if (errors.email) {
		console.log(errors.email)
	} else {
		alert(JSON.stringify(values, null, 2))
	}
})
</script>

<style scoped>
.btn-spacing {
	margin: 10px;
	/* Adjust this value as needed */
}

.view-card {
	width: 400px;
	max-width: 90%;
	/* Prevent the card from being too wide on small screens */
	margin: 0 auto;
	/* Center the card */
	padding: 20px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	/* Add drop shadow */
}

.form-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.form-item {
	margin-bottom: 35px;
}

.input-field {
	width: calc(100% - 40px);
	box-shadow: 0px 0px 0px 1px #868FA0 16%;
}

h2 {
	padding: 20px;
}

v-text-field {
	width: 100%;
}

/* If you want the container to also be centered vertically on the page */
body,
html {
	height: 100%;
}

body {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
