import { createClient } from '@supabase/supabase-js'
import { reactive } from 'vue';


const supabaseUrl = process.env.VUE_APP_SUPABASE_URL;
const supabaseKey = process.env.VUE_APP_SUPABASE_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey)

export const userState = reactive({
  currentUser: null,
});

supabase.auth.onAuthStateChange((event, session) => {
  if (event === "SIGNED_IN" || event === "USER_UPDATED") {
    userState.currentUser = session.user;
  } else if (event === "SIGNED_OUT") {
    userState.currentUser = null;
  }
});