<!-- Shows a filled out document routed from /:tenant/documents  -->
<template>
  <div class="view-container">
    <v-card>
      <div v-html="htmlContent"></div>
    </v-card>
    <v-snackbar v-model="showErrorSnackbar" color="error">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { supabase, userState } from "../supabase";
import { useRoute } from 'vue-router'

export default {
  setup() {
    const htmlContent = ref('')
    const document_id = ref(null)
    const route = useRoute()
    const id = ref(null)
    let showErrorSnackbar = ref(false);
    let errorMessage = ref("");

    onMounted(async () => {
      document_id.value = route.params.id

      id.value = route.params.id;
      // gets current user
      const user = userState.currentUser;
      if (!user) {
        console.error('User not found');
        showErrorSnackbar.value = true;
        errorMessage.value = 'User not found';
        return;
      }

      // Retrieving tenant-specific org id
      const { data: organisations, error: orgError } = await supabase
        .from('organisations')
        .select('id')
        .contains('associated_user_ids', [user.id]);
      if (orgError) {
        console.error(orgError);
        showErrorSnackbar.value = true;
        errorMessage.value = orgError.message;
        return;
      }
      const organisationIds = organisations.map(org => org.id);

      try {
        // Look up the supabase table "documents"
        let { data: documents, error } = await supabase
          .from('documents')
          .select('*')
          .eq('id', document_id.value)
          .in('organisation', [organisationIds[0]])
        if (error) {
          console.error(error);
          showErrorSnackbar.value = true;
          errorMessage.value = error.message;
          return;
        }
        const documentName = documents[0].name
        console.log("document called", documentName)
        const { data, error: downloadError } = await supabase
          .storage
          .from('coveredbucket')
          .download(documentName)

        if (downloadError) {
          console.error('Error fetching HTML:', downloadError)
          throw downloadError
        }

        console.log('Fetched data:', data) // Log the fetched data

        if (data instanceof Blob) {
          const arrayBuffer = await data.arrayBuffer();
          const text = new TextDecoder("utf-8").decode(arrayBuffer)
          htmlContent.value = text
        } else {
          console.error('Fetched data is not a Blob:', data)
          showErrorSnackbar.value = true;
          errorMessage.value = "Fetched data is not a Blob:";
        }
      } catch (error) {
        console.error('Error in onMounted:', error)
      }
    })

    return {
      htmlContent,
    }
  },
}
</script>

<style scoped>
.view-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
