<template>
    <p>{{ $t("microsoftCalendarOAuth.description") }}</p>
    <p>{{ $t("microsoftCalendarOAuth.remainingTime", { time: remainingTime }) }}</p>
</template>

<script setup>
import { onMounted } from 'vue'
import { ref } from 'vue'
import axios from 'axios'
import { useUser } from '../useUser';
import { useRoute, useRouter } from 'vue-router'
import { supabase, userState } from "../supabase";
const { user } = useUser();
const userId = ref(user.value ? user.value.id : null);
const route = useRoute()
const remainingTime = ref(10);
const tenant = ref('')
const router = useRouter();

onMounted(async () => {
    try {
        const code = route.query.code
        const user = userState.currentUser;
        const { data: organisations } = await supabase
            .from('organisations')
            .select('name')
            .contains('associated_user_ids', [user.id])
        const orgname = organisations.map(org => org.name)
        tenant.value = orgname[0]

        const tokenResponse = await axios.post(
            `${process.env.VUE_APP_BACKEND_URL}/api/fetchtokensfrommicrosoft`,
            { code: code, user_id: userId.value },
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );

        if (tokenResponse.status === 200) {
            await axios.post(
                `${process.env.VUE_APP_BACKEND_URL}/api/fetchmicrosoftevents`,
                { user_id: userId.value },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }
            );

        } else {
            console.error('Error fetching tokens from Microsoft:', tokenResponse);
        }
    }
    catch (error) {
        console.error('Error during OAuth flow:', error)
    }
    // Set up the timer for auto-transition
    const timer = setInterval(() => {
        remainingTime.value--;
        if (remainingTime.value === 0) {
            clearInterval(timer);
            router.push(`/${tenant.value}/`);
        }
    }, 1000);
})
</script>
