<template>
  <div class="card-container">
    <v-snackbar v-model="showErrorSnackbar" color="error">
      {{ errorMessage }}
    </v-snackbar>
    <v-card style="width: 100%;" class="card rounded-xl">
      <v-breadcrumbs style="margin-left: 15px;">
        <v-breadcrumbs-item>{{ $t('calls.title') }}</v-breadcrumbs-item>
      </v-breadcrumbs>
      <div style="margin-bottom: 15px; padding: 25px; flex; align-items: left;">
        <h1 style="text-align: left">{{ $t('calls.title') }}</h1>

        <div style="display: flex; gap: 15px; margin-bottom: 15px;">
          <!-- Date Input Field -->
          <v-text-field v-model="selectedDate" label="Select Date" type="date" style="width: 25%;"></v-text-field>

          <v-select style="width: 25%" v-model="selectedAdvisors" :items="advisorOptions" :label="$t('calls.advisor2')"
            multiple clearable chips variant="solo"></v-select>
        </div>

        <v-table hover fixed-header height="700px">
          <thead>
            <tr>
              <th class="text-center">{{ $t('calls.date') }}</th>
              <th class="text-center">{{ $t('calls.advisor') }}</th>
              <th class="text-center">{{ $t('calls.customer') }}</th>
              <th class="text-center">{{ $t('calls.conversationType') }}</th>
              <th class="text-center">{{ $t('calls.transcript') }}</th>
              <th class="text-center">{{ $t('calls.coaching') }}</th>
              <th class="text-center">{{ $t('calls.feedback') }}</th>
              <th class="text-center"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="call in displayedCalls" :key="call.id" @click="navigateToTranscript(call.id)">
              <td>{{ call.start }}</td>
              <td v-if="call.advisor"> {{ call.advisor }} </td>
              <td v-else>{{ $t('calls.loading') }}.</td>
              <td v-if="call.customer">{{ call.customer }}</td>
              <td v-else>{{ $t('calls.loading') }}.</td>
              <td>{{ call.scorecardName }}</td>
              <td v-if="call.transcript_file_name">
                <router-link :to="`/${tenant}/calls/${call.id}/transcript-tab`">
                  <span class="label available"><i class="mdi mdi-file-document-outline"></i>
                    {{ $t('calls.available') }}</span>
                </router-link>
              </td>
              <td v-else>n/a</td>
              <td v-if="call.coaching_available">
                <router-link :to="`/${tenant}/calls/${call.id}/coaching-tab`" @click.stop>
                  <span class="label available"><i class="mdi mdi-lightbulb-outline"></i>
                    {{ $t('calls.available') }}</span>
                </router-link>
              </td>
              <td v-else>n/a</td>
              <td v-if="call.feedback">
                <router-link :to="`/${tenant}/calls/${call.id}/coaching-tab`" @click.stop>
                  <span class="label available"><i class="mdi mdi-chat-outline"></i>
                    {{ $t('calls.available') }}</span>
                </router-link>
              </td>
              <td v-else>n/a</td>
              <td class="text-center">
                <v-icon @click="confirmDelete(call)" class="delete-icon">
                  mdi-delete-outline
                </v-icon>
              </td>
            </tr>
          </tbody>
        </v-table>
        <div class="text-center mt-4">
          <v-pagination v-model="currentPage" :length="totalPages" :total-visible="7"></v-pagination>
        </div>
      </div>
    </v-card>
    <v-dialog v-model="showDeleteDialog" style="max-width: 800px">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('calls.deleteCall') }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="tonal" color="secondary" text @click="showDeleteDialog = false">{{ $t('calls.cancel')
            }}</v-btn>
          <v-btn variant="tonal" color="#EC5B84" text @click="deleteCall">{{ $t('calls.delete') }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { supabase, userState } from "../supabase";
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const calldata = ref([]);
    const route = useRoute();
    const tenant = route.params.tenant;
    const showErrorSnackbar = ref(false);
    const errorMessage = ref("");
    const showDeleteDialog = ref(false);
    const callToDelete = ref(null);
    const router = useRouter();

    const selectedDate = ref(null); // Should be a date string in "YYYY-MM-DD" format
    const selectedAdvisors = ref([]);
    const currentPage = ref(1);
    const pageSize = ref(20);

    const headers = [
      { text: "Datum", value: "created_at" },
      { text: "Berater", value: "advisor" },
      { text: "Kunde", value: "customer" },
      { text: "Gesprächstyp", value: "scorecardName" },
      { text: "Transkript", value: "transcript_file_name" },
      { text: "Coaching", value: "coaching_available" },
    ];

    const advisorOptions = computed(() => {
      return [...new Set(calldata.value.map((call) => call.advisor).filter(Boolean))];
    });

    const totalPages = computed(() => Math.ceil(filteredCalls.value.length / pageSize.value));

    const displayedCalls = computed(() => {
      const start = (currentPage.value - 1) * pageSize.value;
      const end = start + pageSize.value;
      return filteredCalls.value.slice(start, end);
    });

    const filteredCalls = computed(() => {
      // Start with a fresh copy of all calls each time
      let filtered = [...calldata.value];

      // Filter by selected advisors
      if (selectedAdvisors.value.length > 0) {
        filtered = filtered.filter((call) =>
          selectedAdvisors.value.includes(call.advisor)
        );
      }

      // Filter by selected date
      if (selectedDate.value) {
        filtered = filtered.filter((call) => {
          // Parse call.start which is in dd.mm.yyyy format
          const [day, month, year] = call.start.split('.');
          const callDate = new Date(`${year}-${month}-${day}T00:00:00Z`); // Convert to ISO format

          // Parse selectedDate.value which is already in yyyy-mm-dd format
          const selectedDateOnly = new Date(`${selectedDate.value}T23:59:59.999Z`); // Convert to end of the day

          return callDate <= selectedDateOnly; // Compare the dates
        });
      }

      // Apply final filter
      return filtered.filter((call) => call.transcript_file_name && call.coaching_available);
    });


    // Watch for changes in the selected date
    watch(selectedDate, (newDate) => {
      // Handle date changes here if needed (e.g., logging, additional logic)
      console.log("Date changed to:", newDate);
    });

    onMounted(async () => {
      const user = userState.currentUser;
      if (!user) {
        console.error("User not found");
        showErrorSnackbar.value = true;
        errorMessage.value = "User not found";
        return;
      }

      const { data: organisations, error: orgError } = await supabase
        .from("organisations")
        .select("id")
        .contains("associated_user_ids", [user.id]);
      if (orgError) {
        console.error(orgError);
        showErrorSnackbar.value = true;
        errorMessage.value = orgError.message;
        return;
      }
      const organisationIds = organisations.map((org) => org.id);

      const getData = async () => {
        try {
          const { data: calls, error } = await supabase
            .from("calls")
            .select(
              `id,
              user_id,
              advisor_id,
              customer_id,
              scorecard_id,
              created_at,
              transcript_file_name,
              insights,
              organisation,
              coaching_available,
              feedback,
              advisors (first_name, last_name),
              customers (first_name, last_name),
              scorecards (name)`
            )
            .eq("organisation", organisationIds)
            .not("transcript_file_name", "is", null)
            .not("insights", "is", null);

          if (error) {
            console.error(error);
            showErrorSnackbar.value = true;
            errorMessage.value = error.message;
            return;
          }

          calls.sort((a, b) => b.id - a.id);
          calldata.value = calls.map((call) => {
            return {
              id: call.id,
              created_at: new Date(call.start).toLocaleString("de-DE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              }),
              advisor: call.advisors
                ? `${call.advisors.first_name} ${call.advisors.last_name}`
                : "Lädt...",
              customer: call.customers
                ? `${call.customers.first_name} ${call.customers.last_name}`
                : "Lädt...",
              scorecardName: call.scorecards?.name || "n/a",
              transcript_file_name: call.transcript_file_name ? call.transcript_file_name[0] : "n/a",
              coaching_available: call.coaching_available,
              feedback: call.feedback,
            };
          });
        } catch (error) {
          console.error(error);
          showErrorSnackbar.value = true;
          errorMessage.value = error.message;
        }
      };

      await getData();
    });

    const navigateToTranscript = (callId) => {
      console.log("navigatingtotranscript")
      router.push(`/${tenant}/calls/${callId}/transcript-tab`);
    };

    const confirmDelete = (call) => {
      callToDelete.value = call;
      showDeleteDialog.value = true;
    };

    const deleteCall = async () => {
      if (!callToDelete.value) return;
      const { error } = await supabase
        .from("calls")
        .delete()
        .eq("id", callToDelete.value.id);

      if (error) {
        console.error(error);
        showErrorSnackbar.value = true;
        errorMessage.value = error.message;
      } else {
        calldata.value = calldata.value.filter((call) => call.id !== callToDelete.value.id);
        showDeleteDialog.value = false;
      }
    };

    return {
      calldata,
      headers,
      tenant,
      showErrorSnackbar,
      errorMessage,
      confirmDelete,
      showDeleteDialog,
      deleteCall,
      selectedAdvisors,
      advisorOptions,
      selectedDate,
      filteredCalls,
      currentPage,
      pageSize,
      totalPages,
      displayedCalls,
      navigateToTranscript,
    };
  },
};
</script>

<style scoped>
.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.label.available {
  background-color: #D3FFE7;
  color: #00AC4F;
  border-radius: 4px;
  padding: 5px;
  font-size: 0.875em;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: #D3FFE7;
}

.combined-label {
  display: flex;
  align-items: center;
}

.combined-label .icon-feedback {
  margin-right: 5px;
}

.delete-icon {
  color: #9197B3;
}

.delete-icon:hover {
  color: #EC5B84;
}
</style>