<template>
  <div class="card-container">
    <v-card style="width: 100%;" class="card rounded-xl">
      <!-- <v-card style="width: 80%; margin: 0 auto; padding: 25px; box-sizing: border-box; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 15px;" class="card rounded-xl"> -->
      <v-breadcrumbs style="margin-left: 15px;">
        <v-breadcrumbs-item>{{ $t("settings.title") }}</v-breadcrumbs-item>
      </v-breadcrumbs>
      <div style="margin-bottom: 15px; padding: 25px; display: flex; flex-direction: column; align-items: flex-start;">
        <h1 style="text-align: left; margin-bottom: 20px;">{{ $t("settings.title") }}</h1>
        <v-table fixed-header :hover="true">
          <thead>
          </thead>
          <tbody>
            <tr @click="navigateTo('/:tenant/settings/general')" style="cursor: pointer;">
              <td>{{ $t("settings.general") }}</td>
            </tr>
            <tr @click="navigateTo('/:tenant/settings/auth')" style="cursor: pointer;">
              <td>{{ $t("settings.calendar") }}</td>
            </tr>
            <tr @click="navigateTo('/:tenant/settings/conversationtypes')" style="cursor: pointer;">
              <td>{{ $t("settings.conversationTypes") }}</td>
            </tr>
          </tbody>
        </v-table>
      </div>
    </v-card>
  </div>
</template>

<script>

import { useRouter } from 'vue-router';

export default {
  name: 'ViewSettings',
  setup() {
    const router = useRouter();

    function navigateTo(path) {
      router.push(path);
    }

    return { navigateTo };
  }
}
</script>

<style scoped>
td {
  text-align: left;
}

.view-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}

.settings-container {
  width: 25%;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}
</style>