import posthog from "posthog-js";

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init(
      "phc_f9aHNQvp3CrPRLB4zDbxf9A44zJTtLVyhAp3ZlFSSgm",
      {
        api_host: "https://eu.posthog.com",
        capture_pageview: false,
      }
    );
  },
};